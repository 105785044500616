import React from 'react'

const PrivacyPolicy = () => {
    return (
        <>
            <div>
                <div style={{
                    height: "80px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                }}>
                    <h1 style={{ textAlign: "center", textDecoration: "underline" }}>Privacy Policy</h1>
                </div>
                <div>
                    <p className="container" style={{ fontSize: "18px", fontFamily: 'Roboto' , marginBottom: "30px"  }}> Krishna Innovative Software Pvt. Ltd operates the KIS Attendance App which provides it’s team to mark the attendance
                        This page is used to inform website visitors/app regarding our policies with the collection, use, and disclosure of Personal Information if anyone decided to use our Service. If you choose to use our Service, then you agree to the collection and use of information in relation with this policy. The Personal Information that we collect are used for providing and improving the Service. We will not use or share your information with anyone except as described in this Privacy Policy. The terms used in this Privacy Policy have the same meanings as in our Terms and Conditions, which is accessible at , unless otherwise defined in this Privacy Policy.
                        At KIS Attendence , we respect your right to privacy and we understand that visitors to KIS Attendence  (“App”)need to control the uses of their personal information. The privacy policy below describes the measures taken by us to protect your privacy in connection with your use of our Site.
                        KIS Attendence  does not allows third party and servers, ad agencies, ad technology vendors and research firms (“Advertisers”), to serve advertisements to the Users (“Advertised Content”), which may include third party banners, badges, contextual advertising and content created or provided by an Advertiser (collectively referred to as “Advertisements” or “Advertising”).
                        The KIS Attendence  Content is available on the app, including without limitation, text, copy, audio, video, photographs, illustrations, graphics and other visuals, is for targeted at general public for informational purposes only and does not constitute professional medical advice, diagnosis, treatment or recommendations of any kind.
                        The Company reserves the right to change or modify these Terms of Use or any policy or guideline of the Website including the Privacy Policy, at any time and in its sole discretion. Any changes or modifications will be effective immediately upon posting the revisions on the Website, and you waive any right you may have to receive specific notice of such changes or modifications. Your continued use of the Website will confirm your acceptance of such changes or modifications; therefore, you should frequently review these Terms of Use and applicable policies to understand the terms and conditions that apply to your use of the Website.
                        <br></br>
                        <div style={{ marginTop: "30px" }}>
                            <strong style={{ fontWeight: "800" }}>Information Collection and Use – </strong> For a better experience while using our Service, we may require you to provide us with certain personally identifiable information, including but not limited to your name, phone number, and postal address. The information that we collect will be used to contact or identify you.
                        </div>
                        <div style={{ marginTop: "30px" }}>
                            <strong style={{ fontWeight: "800" }}>Information You Provide –</strong> KIS Attendence  does not collect any personal information from those who visit the Site unless a visitor is uploading data on KIS Attendence  in which case we store your email and/or phone number to associate your uploaded data with you. We ensure that the data you upload is anonymized prior to any usage on our website.
                        </div>
                        <div style={{ marginTop: "30px" }}>
                            <strong style={{ fontWeight: "800" }}>Communications With Us –</strong> When you contact us by email or other communications, we may retain that information in order to respond to your request and improve our services.
                        </div>
                        <div style={{ marginTop: "30px" }}>
                            <strong>
                                Log Data –
                            </strong> We want to inform you that whenever you visit our Service, we collect information that your browser sends to us that is called Log Data. This Log Data may include information such as your computer’s Internet Protocol (“IP”) address, browser version, pages of our Service that you visit, the time and date of your visit, the time spent on those pages, and other statistics. We will use this log information to help diagnose problems with our server and to administer our Site. Your log information also may be used to gather broad demographic information.
                        </div>

                        <div style={{ marginTop: "30px" }}>
                            <strong>
                                Cookies –
                            </strong> “Cookies” are files with small amount of data that is commonly used an anonymous unique identifier. These are sent to your browser from the website that you visit and are stored on your computer’s hard drive. Our website uses these “cookies” to saved information locally to improve our Service. You have the option to either accept or refuse these cookies, and know when a cookie is being sent to your computer. If you choose to refuse our cookies, you may not be able to use some portions of our Service.
                        </div>

                        <div style={{ marginTop: "30px" }}>
                            <strong>
                                Security –
                            </strong> We work hard to protect KIS Attendence  and our users from unauthorized access to or unauthorized alteration, disclosure or destruction of information we hold. We maintain industry standard security measures to protect sensitive data. . If you register to establish an account or to receive price alerts, your password is also encrypted. Although we will exercise reasonable care in providing secure transmission of information between your computer and our servers, we cannot ensure or warrant the security of any information transmitted to us over the Internet and we accept no liability for any unintentional disclosure.
                        </div>

                        <div style={{ marginTop: "30px" }}>
                            <strong>
                                CHANGES TO THIS PRIVACY POLICY –
                            </strong> We may make changes in our privacy policy from time to time. When we do so, we will post the revised privacy policy on our Site. Please check the revision date at the top of this page to determine if the policy has been modified since you last reviewed it.
                        </div>

                        <div style={{ marginTop: "30px" }}>
                            <strong>
                                ACCEPTANCE OF PRIVACY POLICY –
                            </strong> Your use of our Site, including any dispute concerning privacy, is subject to this Privacy Policy and the Terms of Use posted on our Site.
                            <strong>BY USING OUR SITE, YOU ARE ACCEPTING THE PRACTICES SET OUT IN THIS PRIVACY POLICY.</strong>
                        </div>
                    </p>
                </div>
            </div>

        </>
    )
}

export default PrivacyPolicy