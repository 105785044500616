import React, { useEffect, useState } from "react";
import { httpClient } from "../../constants/Api";
import { useParams, Link, useHistory } from 'react-router-dom';
import { toast } from "react-toastify";
import { DOCS } from "../../constants/AppConstants";
import moment from "moment";
import { FileIcon, defaultStyles } from "react-file-icon";
import { filesize } from "filesize";
import { saveAs } from "file-saver";

const PublicDocuments = () => {
    const { folderId } = useParams();  // Retrieve folderId from URL
    const history = useHistory();        // Initialize useHistory
    const [nodeData, setNodeData] = useState();
    const [loading, setLoading] = useState(false);
    const [allDirectories, setAllDirectories] = useState([]);

    useEffect(() => {
        if (folderId) {
            getFolderData(folderId);  // Fetch folder data based on the folderId
        }
    }, [folderId]);

    const getFolderData = async (folderId) => {
        try {
            setLoading(true);
            const response = await httpClient.get(DOCS.GET_FOLDER_PUBLIC_URL_DATA.replace("{folderId}", folderId));
            if (response.status === 200) {
                setNodeData(response.data.result.getFomattedData);
                const flattanDirectories = await flattanTheArray([
                    response.data.result.getFomattedData,
                    response.data.result.getFomattedData.children,
                ]);
                setAllDirectories(flattanDirectories);
            }
        } catch (err) {
            console.error("Error fetching folder data", err);
            toast.error("Error in fetching folder data");
        } finally {
            setLoading(false);
        }
    };

    const onSelectNode = async (nodeData) => {
        if (nodeData.type === "Folder") {
            // Update the URL with the selected folder ID using history.push
            history.push(`/kis-attendance/public-docs/${nodeData._id}`);
            // Fetch child data when a folder is clicked
            getFolderData(nodeData._id);  // Assuming nodeData has the folder's _id
        }
    };

    function flattanTheArray(array) {
        var result = [];
        array.forEach(function (a) {
            if (a.type === "Folder") {
                result.push(a);
                if (Array.isArray(a.children)) {
                    result = result.concat(flattanTheArray(a.children));
                }
            }
        });
        return result;
    }

    const handleFilPreViewClick = (data) => {
        {
            ["jpg", "png", "gif", "txt", "pdf"].includes(
                data.path.substring(data.path.lastIndexOf(".") + 1)
            ) ? handlePreviewClick(data._id) : handleDownloadClick(data)
        }
    }

    const handlePreviewClick = (docID) => {
        const type = "doc_vault";
        window.open(`/kis-attendance/preview/public-doc/${docID}?type=${type}`, "_blank");
    };

    const handleDownloadClick = (data) => {
        const url = data.path;
        fetch(url)
            .then((response) => {
                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
                return response.blob();
            })
            .then((blob) => {
                const fileExtension = data.path.substring(
                    data.path.lastIndexOf(".") + 1
                );
                const fileName = data.name + "." + fileExtension;
                saveAs(blob, fileName);
            })
            .catch((error) => {
                console.error("There was a problem with the fetch operation:", error);
            });
    };

    return (
        <>
            <div className="row w-100">
                <div className="col-md-12">
                    <div className="copy-link-document py-3 px-3 bg-white">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th scope="col">Name</th>
                                    <th scope="col">Last modified</th>
                                    <th scope="col">Added By</th>
                                    <th scope="col">Size</th>
                                </tr>
                            </thead>
                            <tbody>
                                {nodeData &&
                                    nodeData.children &&
                                    nodeData.children.map(
                                        (data, i) =>
                                            data.type === "Folder" && (
                                                <tr key={i + 1}>
                                                    <td onDoubleClick={() => onSelectNode(data)}>
                                                        <span className="me-2 file-type-icons">
                                                            <i className="fa fa-folder" aria-hidden="true"></i>
                                                        </span>
                                                        {data.name}
                                                    </td>
                                                    <td>{moment(data.updatedAt).format("lll")}</td>
                                                    <td>{data.createdBy && data.createdBy.name
                                                        ? data.createdBy.name
                                                        : "-"}</td>
                                                    <td>{"-"}</td>
                                                </tr>
                                            )
                                    )}
                                {nodeData &&
                                    nodeData.children &&
                                    nodeData.children.map(
                                        (data, i) =>
                                            data.type === "File" && (
                                                <tr key={i + 1}>
                                                    <td onClick={() => handleFilPreViewClick(data)}>
                                                        <Link style={{ wordBreak: 'break-all' }} className="d-flex row-highlight" to="#">
                                                            <span className="pdf-file-icon">
                                                                <FileIcon
                                                                    extension={data.path.substring(
                                                                        data.path.lastIndexOf(".") + 1
                                                                    )}
                                                                    {...defaultStyles[
                                                                    data.path.substring(
                                                                        data.path.lastIndexOf(".") + 1
                                                                    )
                                                                    ]}
                                                                />
                                                            </span>
                                                            {data.name}
                                                        </Link>
                                                    </td>
                                                    <td>{moment(data.updatedAt).format("lll")}</td>
                                                    <td>{data.createdBy && data.createdBy.name
                                                        ? data.createdBy.name
                                                        : "-"}</td>
                                                    <td>
                                                        {filesize(data.fileSize, {
                                                            base: 2,
                                                            standard: "jedec",
                                                        })}
                                                    </td>
                                                </tr>
                                            )
                                    )}
                            </tbody>
                        </table>
                    </div>
                </div>
                {
                    nodeData &&
                    nodeData.children.length === 0 &&
                    <p className="text-center">No records to display</p>
                }
            </div>
        </>
    );
};

export default PublicDocuments;

