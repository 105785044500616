import React, { useState, useRef } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

function CkEditor({ values, setValues, setMessage, type, inputFields, inputFields1, userId }) {
  const [matchedUsers, setMatchedUsers] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [highlightedIndex, setHighlightedIndex] = useState();
  const editorRef = useRef(null);

  const customDecode = (html) => {
    const decodedHtml = html.replace(/&amp;/g, '&').replace(/&lt;/g, '<').replace(/&gt;/g, '>');
    return decodedHtml;
  };

  function stripHtmlTags(html) {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent || '';
  }

  const updateLinkPreview = async (editor) => {
    if (!editor) return;

    const data = editor.getData();
    const plainText = stripHtmlTags(data);

    const atIndex = plainText.lastIndexOf('@');
    if (atIndex !== -1) {
      const query = plainText.slice(atIndex + 1).toLowerCase();
      const combinedUsers = [...inputFields, ...inputFields1];
      let matchedUsers = [];

      if (combinedUsers && combinedUsers.length > 0 && userId && userId.length > 0) {
        matchedUsers = userId.filter(user =>
          combinedUsers.some(combinedUser => combinedUser === user.value)
        );
        setMatchedUsers(matchedUsers);
        setShowDropdown(true);
         setHighlightedIndex(0); 
      } else {
        setShowDropdown(false);
      }
    } else {
      setShowDropdown(false);
    }

    const containsLink = /https?:\/\/\S+/i.test(plainText);

    if (containsLink) {
      try {
        const linkMatch = plainText.match(/(https?:\/\/\S+)/i);
        const link = linkMatch ? linkMatch[1] : '';
        const title = 'Review Link';
        const previewHTML = `
          ${plainText.substring(0, linkMatch.index)}
          <a href="${link}" rel="noopener noreferrer" style="text-decoration: none;">
            ${title}
          </a>
          ${plainText.substring(linkMatch.index + link.length)}`;
        editor.setData(previewHTML);
        if (type === 'edit') {
          setMessage(previewHTML);
        }

        const newObj = { ...values, message: previewHTML };
        setValues(newObj);
      } catch (error) {
        console.error('Error fetching link data:', error);
      }
    } else {
      if (type === 'edit') {
        setMessage(data);
      }
      const newObj = { ...values, message: data };
      setValues(newObj);
    }
      // Close dropdown on click anywhere in CKEditor
  editor.editing.view.document.on('click', () => {
    setShowDropdown(false);
  });
  };

  const handleUserSelect = (user) => {
      const newSelectedUsers = [user];
      console.log(newSelectedUsers);
      setSelectedUsers(newSelectedUsers);
      updateEditorContent(newSelectedUsers);
  };


  const updateEditorContent = (users) => {
    if (!editorRef.current) return;

    const editor = editorRef.current;
    const data = editor.getData();
    const atIndex = data.lastIndexOf('@');
    const userText = users.map(user => user.label).join(', ');
    const newText = data.substring(0, atIndex) + userText + ' ';
    editor.setData(newText);
    setShowDropdown(false);
    setMatchedUsers([]);
  };

//   const handleKeyDown = (e) => {
//     if (!showDropdown) return;

//     switch (e.key) {
//         case 'ArrowDown':
//             e.preventDefault();
//             // Calculate the new index and update the state
//             setHighlightedIndex(prevIndex => {
//                 const newIndex = (prevIndex + 1) % matchedUsers.length;
//                 console.log('ArrowDown: Previous Index:', prevIndex, 'New Index:', newIndex);
//                 return newIndex;
//             });
//             break;

//         case 'ArrowUp':
//             e.preventDefault();
//             // Calculate the new index and update the state
//             setHighlightedIndex(prevIndex => {
//                 const newIndex = (prevIndex - 1 + matchedUsers.length) % matchedUsers.length;
//                 console.log('ArrowUp: Previous Index:', prevIndex, 'New Index:', newIndex);
//                 return newIndex;
//             });
//             break;

//         case 'Enter':
//             e.preventDefault();
//             // Use the latest highlightedIndex to select the user
//             if (matchedUsers.length > 0) {
//                 setHighlightedIndex(newIndex => {
//                     const userIndex = newIndex >= 0 && newIndex < matchedUsers.length ? newIndex : 0;
//                     console.log('Enter Key:  Usture or false:', newIndex >= 0 && newIndex < matchedUsers.length );
//                     console.log('Enter Key:  User:', userIndex);
//                     const selectedUser = matchedUsers[userIndex];
//                     console.log('Enter Key: Selected User:', selectedUser);
//                     handleUserSelect(selectedUser);
//                     return newIndex; // Return current index as no change needed
//                 });
//             }
//             break;

//         default:
//             break;
//     }
// };




  return (
    <div style={{ position: 'relative' }}>
      <CKEditor
        editor={ClassicEditor}
        config={{
          toolbar: ['heading', '|', 'bold', 'italic', 'link', '|', 'undo', 'redo', 'bulletedList', 'numberedList', 'blockQuote', 'indent', 'outdent'],
        }}
        data={customDecode(values.message)}
        onReady={(editor) => {
          editorRef.current = editor;
        }}
        onChange={(event, editor) => {
          updateLinkPreview(editor);
        }}
      />
   {showDropdown && (
        <div style={{ position: 'absolute', top: '50px', left: '10px', width: '310px', border: '1px solid #ccc', background: '#fff', zIndex: "10", borderRadius:"5px", fontSize: "14px", boxShadow: "0px 0px 5px #e3e1e1", }} className="message-dropdown">
          {matchedUsers.map((user, index) => (
            <div
              key={user.value}
              onClick={() => handleUserSelect(user)}
              style={{
                cursor: 'pointer',
                padding: '10px 10px',
                 backgroundColor: highlightedIndex === index ? '#e0e0e0' : 'transparent',
              }}
            >
              {user.label}
            </div>
          ))}
        </div>
      )}

    </div>
  );
}

export default CkEditor;